@import "styles/shared";

.app-footer {
	&__colophon {
		padding-right: env( safe-area-inset-right );
		padding-left: env( safe-area-inset-left );
		background: rgba( 255, 255, 255, .1 );
		box-shadow: inset 0 2px 0 0 rgba( 255, 255, 255, .25 );

		&-inner {
			@include content-inner;
			padding-top: 1rem;
			padding-bottom: 1rem;
			display: flex;
			justify-content: space-between;

			@media screen and ( max-width: 950px ) {
				flex-direction: column-reverse;
			}
		}
	}
}

.colophon__list {
	list-style: none;
	margin: 0;
	padding: 0;

	&-item {
		display: inline-block;
		margin: 0 1rem 0 0;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&--is-light {
			opacity: .5;
		}
	}

	a {
		color: $color-white;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}

		@media screen and ( max-width: 400px ) {
			line-height: 2;
		}
	}

	@media screen and ( max-width: 950px ) {
		+ .colophon__list {
			margin-bottom: 1rem;
		}
	}
}

.app-copyright {
	&__date {
		margin-right: .5em;
	}

	// Only visible when printing a professions page.
	&__sdbb {
		display: none;
	}
}
