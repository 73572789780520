@import "styles/shared";

.app-header {
	z-index: 10;
	position: relative;
	box-shadow: 0 2px 4px 0 rgba( 0, 0, 0, .1 );
	background: $color-white;

	@media screen and ( max-width: 799px ) {
		padding-top: .625rem;
		padding-bottom: .625rem;
	}

	@media screen and ( min-width: 800px ) {
		display: flex;
		padding-top: 1.5625rem;
		padding-bottom: 1.5625rem;
	}

	@media screen and ( min-width: 1000px ) {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	&__inner {
		@include content-inner;

		display: flex;

		@media screen and ( max-width: 799px ) {
			background: $color-white;
		}
	}

	&__buttons {
		display: flex;

		@media screen and ( max-width: 799px ) {
			width: 100%;
		}
	}

	&__home-link {
		display: inline-block;

		&:hover,
		&:focus {
			.app-header__logo g path {
				fill: $color-text-copy;
			}
		}
	}

	&__logo {
		max-height: 80px;
		width: auto;

		@media screen and ( max-width: 800px ) {
			max-height: 40px;
		}
	}

	.mobile-search-open,
	.mobile-menu-open {
		@include reset-button;

		display: flex;
		gap: .625rem;
		align-items: center;
		z-index: 3;
		padding: .625rem .9375rem;
		font-size: .75rem;
		line-height: 1.333;
		font-family: $medium-font-family;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: .03125rem;
		color: $color-dark-gray;

		@media screen and ( min-width: 800px ) {
			display: none;
		}
	}

	.mobile-search-open {
		margin-left: auto;
	}

	.mobile-menu-open {
		&:hover,
		&:focus {
			background: none;
			box-shadow: none;
			color: $color-dark-gray;

			.line {
				background-color: tint( $color-petrol, 50% );

				.page-theme--bordeaux & {
					background-color: tint( $color-bordeaux, 50% );
				}

				.page-theme--yellow & {
					background-color: tint( $color-yellow, 50% );
				}

				.page-theme--olive-green & {
					background-color: tint( $color-olive-green, 50% );
				}

				.page-theme--blue & {
					background-color: tint( $color-blue, 50% );
				}

				.page-theme--orange-red & {
					background-color: tint( $color-orange-red, 50% );
				}

				.page-theme--anthrazit & {
					background-color: tint( $color-anthrazit, 50% );
				}
			}
		}

		.line {
			display: block;
			width: 1.25rem;
			height: .125rem;
			margin: .1875rem 0;
			border-radius: 2px;
			background: currentcolor;
			transition: all 120ms ease-in-out;
		}

		&[aria-expanded="true"] {
			.line {
				&.first {
					transform: translate3d( 0, 5px, 0 ) rotate( 45deg );
				}

				&.second {
					transform: translate3d( 0, 0, 0 ) rotate( -45deg );
				}

				&.third {
					opacity: 0;
				}
			}
		}
	}
}

.app-header__logo g path {
	fill: $color-petrol;

	.page-theme--bordeaux & {
		fill: $color-bordeaux;
	}

	.page-theme--yellow & {
		fill: $color-yellow;
	}

	.page-theme--olive-green & {
		fill: $color-olive-green;
	}

	.page-theme--blue & {
		fill: $color-blue;
	}

	.page-theme--orange-red & {
		fill: $color-orange-red;
	}

	.page-theme--anthrazit & {
		fill: $color-anthrazit;
	}

	@media print {
		fill: $color-petrol !important;
	}
}
